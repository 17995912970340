
import Swal from 'sweetalert2'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      purposes: [],
      consentSelected: []
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapGetters(['isAuthenticated'])
  },
  mounted () {
    console.log('userData', this.userData, 'isAuth', this.isAuthenticated)
    this.getActivePurposes()
  },
  methods: {
    async getActivePurposes () {
      console.log('userData', this.userData, 'isAuth', this.isAuthenticated)
      const params = {
        params: {
          phone: '0803061924'
        }
      }
      try {
        const result = await this.$axios.$get('scgppdpa-get-active-purposes', params)
        if (result) {
          console.log('result >>>>', result)
          this.purposes = result.data.purposes
        }
      } catch (errors) {
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    },
    async submitPurposes () {
      console.log('userData', this.userData, 'isAuth', this.isAuthenticated)
      const params = {
        params: {
          phone: this.userData.phone
        }
      }
      try {
        const result = await this.$axios.$get('scgppdpa-get-active-purposes', params)
        if (result) {
          console.log('result >>>>', result)
          this.purposes = result.data.purposes
        }
      } catch (errors) {
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    }
  }
}
